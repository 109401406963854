.home {
  font-family:  $font-family-sans-serif;
  .about {
    h2 {
      font-size: rem-calc(20);
      margin-bottom: rem-calc(28);
      text-transform: uppercase;
      text-align: center;
      margin-top: 1rem;

      @media #{$small-up} {
        font-size: rem-calc(25);
      }

      @media #{$medium-up} {
        font-size: rem-calc(29);
      }

      @media #{$large-up} {
        font-size: rem-calc(32);
      }
    }
    h1 {
      margin-top: rem-calc(44);
      color: #4a4a4a;
      font-size: rem-calc(33);
      font-weight: bold;
    }
    .lead {
       color: #4a4a4a;
       font-size: rem-calc(20);
       font-weight: 300;
       text-align: center;
    }
    img {
      margin-top: rem-calc(20);
      margin-bottom: rem-calc(45);
    }
  }

  .more-news {
    text-align: center;
    margin-bottom: rem-calc(54);
    font-size: rem-calc(18);
  }

  @media #{$medium-up} {
    .news {
      h2 {
        margin-top: 3rem;
      }
    }
  }

  time {
    position: relative;
    padding-left: 10px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      background: $secondary-color;
      width: 5px;
      height: 11px;
      left: 0;
      top: 50%;
      margin-top: -5.5px;
    }

    @media #{$medium-up} {
      font-size: rem-calc(15);
    }
  }

  article {
    margin-bottom: 2rem;

    h3 {
      border: 0;
      padding: 0;
      font-size: rem-calc(23);

      @media #{$medium-up} {
        font-size: rem-calc(25);
      }

      @media #{$large-up} {
        font-size: rem-calc(24);
      }
    }

    img {
      margin-bottom: rem-calc(15);
    }
  }

  .read-more {
    background: $primary-color;
  }
  @media #{$small-only} {
    .news-item {
      margin-left: rem-calc(30);
    }
  }
  @media #{$small-up} {
    .news-main {
      @include grid-row($behavior: nest);
    }

    .news-img {
      @include grid-column($columns: 4, $push: 8);
    }

    .news-txt {
      @include grid-column($columns: 8, $pull: 4);
    }
  }

  @media #{$large-up} {
    .news-container {
      @include grid-row($behavior: nest);
    }
  }
}

.about {
  text-align: center;
}
#mosaic {
  text-align: center;
  h3 {
    color: #0d0d0d;
    font-weight: bold;
    font-size: rem-calc(15);
    line-height: 1.2;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    margin-top: rem-calc(11);
    a {
      color: #0d0d0d;
      background-color: $white;
      text-decoration: none;

    }
  }
  p {
    color: #0d0d0d;
    font-size: rem-calc(12);
    font-weight: 300;
    letter-spacing: 2px;
    line-height: 1.5;
    text-transform: uppercase;
    margin-top: rem-calc(-5);

  }

  a {
    background-color: #ca7f13;
    display: inline-block;
    img{
      vertical-align: middle;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
    }
    &:hover img{
      opacity: 0.5;
    }
  }
}
.events-news {
  margin-top: rem-calc(40);
  text-align: center;
  h2 {
    font-size: rem-calc(20);
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 3.3px;
    color: #4a4a4a;
    &:before {
      content: " -  ";
      width: 25px;
      height: 1px;
      color: #979797;
    }
    &:after {
      content: "  - ";
      width: 25px;
      height: 1px;
      color: #979797;
    }
  }
  .categ {
    text-transform: uppercase;
    color: $primary-color;
    font-size: rem-calc(14);
    font-weight: normal;
    margin-bottom: rem-calc(5);
    text-align: left;
    text-decoration: none;
  }
  h3 {
    font-size: rem-calc(24);
    line-height: 1;
    color: #4a4a4a;
    text-align: left;
    a {
      text-decoration: none;
      color: #4a4a4a;
      font-weight: 300;
    }
  }
  p {
    font-size: rem-calc(14);
    font-weight: normal;
    color: #9b9b9b;
    text-align: left;
  }
  .button {
    background-color: transparent;
    border: solid 1px #ca7f13;
    border-radius: 5px;
    font-size: rem-calc(11);
    color: $primary-color;
    text-transform: uppercase;
    height: rem-calc(31);
    text-align: center;
    padding-top: rem-calc(8);
    letter-spacing: 3.1px;
    &:hover {
      border-color: #A36810;
    }
  }
  .not {
    border-right: 1px solid #c8c8c8;
  }
@media only screen and (max-width: $small-breakpoint) {
  .events-news .not {
    padding-left: rem-calc(20);
  }
}  
  .event {
    margin-left: rem-calc(20);
    .data {
      text-align: left;
      font-size: rem-calc(20);
      letter-spacing: 5.7px;
      font-weight: normal;
      color: #d0021b;
      text-transform: uppercase;
      margin-bottom: 0;
      margin-top: rem-calc(50);
      time {
        padding-left: 0;
      &:before {
        background: none;
      }
      }
    }
  }
}