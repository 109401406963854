.top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  text-align: center;
  text-transform: uppercase;
  color: $base;
  padding-top: 1.5em;

  &:before {
    content: '';
    @extend .top;
    display: block;
  }

  &:hover {
    border: 0;
    color: $tuatara;
  }
}
