.virtual {
  #mosaic {
    margin-top: rem-calc(45);
    margin-bottom: rem-calc(50);
    .columns {
      max-height: rem-calc(220);
    }
    a {
    background-color: #000;
    display: inline-block;
    img{
      vertical-align: middle;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      opacity: 0.8;
    }
    &:hover img{
      opacity: 0.5;
    }
  }
  h3 {
    color: #fff;
    font-weight: bold;
    font-size: rem-calc(15);
    line-height: 1.2;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    position: relative;
    top: rem-calc(-150);
  }
  p {
    color: #fff;
    font-size: rem-calc(12);
    font-weight: 300;
    letter-spacing: 2px;
    line-height: 1.5;
    text-transform: uppercase;
    position: relative;
    top: rem-calc(-150);

  }
  .button {
    background-color: transparent;
    border: solid 1px #fff;
    border-radius: 5px;
    font-size: rem-calc(11);
    color: #fff;
    text-transform: uppercase;
    height: rem-calc(31);
    text-align: center;
    padding-top: rem-calc(8);
    letter-spacing: 3.1px;
    position: relative;
    top: rem-calc(-150);
  }
  }
}
@media only screen and (max-width: $xsmall-breakpoint) {
.virtual {
  #mosaic {
    .columns {
      height: auto;
    }
    h3 {
      margin-top: rem-calc(50);
    }
  } 
}
} 
@media only screen and (max-width: 390px) {
.virtual {
  #mosaic {
    .columns {
      height: 170px;
    }
    
  } 
}
} 