.main-footer {
  background: #fff;
  margin-top: 3rem;
  padding: rem-calc(27) 0;
  background-image: url('img/imatge-peu.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  text-align: center;
  background-position: center top;
  margin-top: rem-calc(100);
  .support {
     margin-top: rem-calc(240);
     color: #4a4a4a;
     font-size: rem-calc(14);
     font-weight: bold;
     letter-spacing: 2.3px;
     margin-bottom: rem-calc(45);
  }
  @media only screen and (max-width: $small-breakpoint) {
    .support {
     margin-top: rem-calc(85);
   }
  }
  p,
  ul {
    font-size: rem-calc(14);
    list-style: none;

    @media #{$small-up} {
      font-size: rem-calc(15);
    }

    @media #{$medium-up} {
      font-size: rem-calc(16);
    }

    @media #{$large-up} {
      font-size: rem-calc(17);
    }
    li {
      display: inline;
      a img {
      margin-bottom: rem-calc(50);
      }
    }
  }
  ul {
    width: 70%;
    margin: 0 auto;

    li {
      margin-left: rem-calc(35);
      margin-right: rem-calc(35);
    }
  }

  .info__footer {
    p {
      font-size: rem-calc(11);
      color: #454545;
      font-weight: normal;
      margin-top: rem-calc(18);
      display: inline;
      
    }
    a { 
        font-size: rem-calc(11)}
    .logo-footer {
      display: block;
      margin: 0 auto;
      margin-bottom: rem-calc(20);
    }
  }

  .legal {
     margin-top: rem-calc(20);
    a {
      font-size: rem-calc(9);

    }
    li {
      margin-left: rem-calc(5);
      margin-right: rem-calc(5);
    }
  }
  
}
