.cookies {
    background-color: $primary-color;
    background-color: rgba($primary-color,.9);
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 10;
    padding-top: 1rem;
    padding-bottom: 1rem;

    p {
        color: $white;
        margin: 0;
    }

    a {
        color: $white;
        text-decoration: underline;
    }

    .button {
        text-decoration: none;
        margin-bottom: 0;
        margin-left: 1em;
        padding: .5em .5em;
    }
}
