body {
  font-family:  $font-family-sans-serif;
  text-align: center;
}
  h1 {
      font-size: rem-calc(33);
      color: #4a4a4a;
      font-weight: 700;
      letter-spacing: 5.5px;
      margin-top: 7.5rem;
      text-transform: uppercase;
    }
 h2 {
      font-size: rem-calc(25);
      color: #4a4a4a;
      font-weight: 700;
      letter-spacing: 5.5px;
      text-transform: uppercase;
      text-align: center;
 }

  @media only screen and (max-width: $small-breakpoint) {
    h1 {
      font-size: rem-calc(25);
      margin-top: .5rem;
      letter-spacing: 4.5px;
    }
  }
  .about {
    text-align: center;
    
    h3 {
      font-size: rem-calc(25);
      color: #4a4a4a;
      font-weight: 300;
      margin-top: rem-calc(18);
      margin-left: 15%;
      margin-right: 15%;
      line-height: 1.3;

      @media #{$small-up} {
        font-size: rem-calc(18);
      }

      @media #{$medium-up} {
        font-size: rem-calc(25);
        margin-top: rem-calc(30);
      }

      @media #{$large-up} {
        font-size: rem-calc(25);
        margin-top: rem-calc(30);
      }
    }
    p {
      text-align: left;
      font-size: rem-calc(14);
      color: #4a4a4a;
      margin-left: 15%;
      margin-right: 15%;
      font-weight: normal;

    }
    img {
      margin-top: rem-calc(43);
      margin-bottom: rem-calc(51);

    }
  }
  .img-projecte {
    width: 100%;
    height: auto;
    margin-top: rem-calc(35);
    margin-bottom: rem-calc(40);
  }
  .projecte {
    .figure-container {
      margin-top: rem-calc(35);
      margin-bottom: rem-calc(40);
    }
  }
  article {
    margin-bottom: 2rem;

    h3 {
      border: 0;
      padding: 0;
      font-size: rem-calc(23);

      @media #{$medium-up} {
        font-size: rem-calc(25);
      }

      @media #{$large-up} {
        font-size: rem-calc(24);
      }
    }

    img {
      margin-bottom: rem-calc(15);
    }
  }
.general {
  text-align: left;
 p {
  color: #4a4a4a;
 }
 h2, h3 {
  text-align: left;
 }
 h4, h5, h3 {
  color: #4a4a4a;
 }
 h5 {
  margin-top: rem-calc(15);
 }
}
