/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$compartir-email-name: 'compartir-email';
$compartir-email-x: 92px;
$compartir-email-y: 105px;
$compartir-email-offset-x: -92px;
$compartir-email-offset-y: -105px;
$compartir-email-width: 29px;
$compartir-email-height: 19px;
$compartir-email-total-width: 188px;
$compartir-email-total-height: 171px;
$compartir-email-image: 'img/spritesheet.png';
$compartir-email: (92px, 105px, -92px, -105px, 29px, 19px, 188px, 171px, 'img/spritesheet.png', 'compartir-email', );
$compartir-facebook-name: 'compartir-facebook';
$compartir-facebook-x: 0px;
$compartir-facebook-y: 128px;
$compartir-facebook-offset-x: 0px;
$compartir-facebook-offset-y: -128px;
$compartir-facebook-width: 27px;
$compartir-facebook-height: 27px;
$compartir-facebook-total-width: 188px;
$compartir-facebook-total-height: 171px;
$compartir-facebook-image: 'img/spritesheet.png';
$compartir-facebook: (0px, 128px, 0px, -128px, 27px, 27px, 188px, 171px, 'img/spritesheet.png', 'compartir-facebook', );
$compartir-link-name: 'compartir-link';
$compartir-link-x: 132px;
$compartir-link-y: 0px;
$compartir-link-offset-x: -132px;
$compartir-link-offset-y: 0px;
$compartir-link-width: 31px;
$compartir-link-height: 32px;
$compartir-link-total-width: 188px;
$compartir-link-total-height: 171px;
$compartir-link-image: 'img/spritesheet.png';
$compartir-link: (132px, 0px, -132px, 0px, 31px, 32px, 188px, 171px, 'img/spritesheet.png', 'compartir-link', );
$compartir-telegram-name: 'compartir-telegram';
$compartir-telegram-x: 27px;
$compartir-telegram-y: 128px;
$compartir-telegram-offset-x: -27px;
$compartir-telegram-offset-y: -128px;
$compartir-telegram-width: 29px;
$compartir-telegram-height: 25px;
$compartir-telegram-total-width: 188px;
$compartir-telegram-total-height: 171px;
$compartir-telegram-image: 'img/spritesheet.png';
$compartir-telegram: (27px, 128px, -27px, -128px, 29px, 25px, 188px, 171px, 'img/spritesheet.png', 'compartir-telegram', );
$compartir-twitter-name: 'compartir-twitter';
$compartir-twitter-x: 132px;
$compartir-twitter-y: 32px;
$compartir-twitter-offset-x: -132px;
$compartir-twitter-offset-y: -32px;
$compartir-twitter-width: 30px;
$compartir-twitter-height: 26px;
$compartir-twitter-total-width: 188px;
$compartir-twitter-total-height: 171px;
$compartir-twitter-image: 'img/spritesheet.png';
$compartir-twitter: (132px, 32px, -132px, -32px, 30px, 26px, 188px, 171px, 'img/spritesheet.png', 'compartir-twitter', );
$compartir-whatsapp-name: 'compartir-whatsapp';
$compartir-whatsapp-x: 92px;
$compartir-whatsapp-y: 40px;
$compartir-whatsapp-offset-x: -92px;
$compartir-whatsapp-offset-y: -40px;
$compartir-whatsapp-width: 33px;
$compartir-whatsapp-height: 33px;
$compartir-whatsapp-total-width: 188px;
$compartir-whatsapp-total-height: 171px;
$compartir-whatsapp-image: 'img/spritesheet.png';
$compartir-whatsapp: (92px, 40px, -92px, -40px, 33px, 33px, 188px, 171px, 'img/spritesheet.png', 'compartir-whatsapp', );
$desplegar-name: 'desplegar';
$desplegar-x: 0px;
$desplegar-y: 0px;
$desplegar-offset-x: 0px;
$desplegar-offset-y: 0px;
$desplegar-width: 46px;
$desplegar-height: 46px;
$desplegar-total-width: 188px;
$desplegar-total-height: 171px;
$desplegar-image: 'img/spritesheet.png';
$desplegar: (0px, 0px, 0px, 0px, 46px, 46px, 188px, 171px, 'img/spritesheet.png', 'desplegar', );
$ico-facebook-espais-name: 'ico-facebook-espais';
$ico-facebook-espais-x: 48px;
$ico-facebook-espais-y: 155px;
$ico-facebook-espais-offset-x: -48px;
$ico-facebook-espais-offset-y: -155px;
$ico-facebook-espais-width: 12px;
$ico-facebook-espais-height: 12px;
$ico-facebook-espais-total-width: 188px;
$ico-facebook-espais-total-height: 171px;
$ico-facebook-espais-image: 'img/spritesheet.png';
$ico-facebook-espais: (48px, 155px, -48px, -155px, 12px, 12px, 188px, 171px, 'img/spritesheet.png', 'ico-facebook-espais', );
$ico-facebook-name: 'ico-facebook';
$ico-facebook-x: 16px;
$ico-facebook-y: 155px;
$ico-facebook-offset-x: -16px;
$ico-facebook-offset-y: -155px;
$ico-facebook-width: 16px;
$ico-facebook-height: 16px;
$ico-facebook-total-width: 188px;
$ico-facebook-total-height: 171px;
$ico-facebook-image: 'img/spritesheet.png';
$ico-facebook: (16px, 155px, -16px, -155px, 16px, 16px, 188px, 171px, 'img/spritesheet.png', 'ico-facebook', );
$ico-garrigues-b-name: 'ico-garrigues-b';
$ico-garrigues-b-x: 132px;
$ico-garrigues-b-y: 58px;
$ico-garrigues-b-offset-x: -132px;
$ico-garrigues-b-offset-y: -58px;
$ico-garrigues-b-width: 31px;
$ico-garrigues-b-height: 24px;
$ico-garrigues-b-total-width: 188px;
$ico-garrigues-b-total-height: 171px;
$ico-garrigues-b-image: 'img/spritesheet.png';
$ico-garrigues-b: (132px, 58px, -132px, -58px, 31px, 24px, 188px, 171px, 'img/spritesheet.png', 'ico-garrigues-b', );
$ico-garrigues-name: 'ico-garrigues';
$ico-garrigues-x: 132px;
$ico-garrigues-y: 82px;
$ico-garrigues-offset-x: -132px;
$ico-garrigues-offset-y: -82px;
$ico-garrigues-width: 31px;
$ico-garrigues-height: 24px;
$ico-garrigues-total-width: 188px;
$ico-garrigues-total-height: 171px;
$ico-garrigues-image: 'img/spritesheet.png';
$ico-garrigues: (132px, 82px, -132px, -82px, 31px, 24px, 188px, 171px, 'img/spritesheet.png', 'ico-garrigues', );
$ico-instagram-espais-name: 'ico-instagram-espais';
$ico-instagram-espais-x: 145px;
$ico-instagram-espais-y: 128px;
$ico-instagram-espais-offset-x: -145px;
$ico-instagram-espais-offset-y: -128px;
$ico-instagram-espais-width: 12px;
$ico-instagram-espais-height: 12px;
$ico-instagram-espais-total-width: 188px;
$ico-instagram-espais-total-height: 171px;
$ico-instagram-espais-image: 'img/spritesheet.png';
$ico-instagram-espais: (145px, 128px, -145px, -128px, 12px, 12px, 188px, 171px, 'img/spritesheet.png', 'ico-instagram-espais', );
$ico-instagram-name: 'ico-instagram';
$ico-instagram-x: 0px;
$ico-instagram-y: 155px;
$ico-instagram-offset-x: 0px;
$ico-instagram-offset-y: -155px;
$ico-instagram-width: 16px;
$ico-instagram-height: 16px;
$ico-instagram-total-width: 188px;
$ico-instagram-total-height: 171px;
$ico-instagram-image: 'img/spritesheet.png';
$ico-instagram: (0px, 155px, 0px, -155px, 16px, 16px, 188px, 171px, 'img/spritesheet.png', 'ico-instagram', );
$ico-linkedin-name: 'ico-linkedin';
$ico-linkedin-x: 32px;
$ico-linkedin-y: 155px;
$ico-linkedin-offset-x: -32px;
$ico-linkedin-offset-y: -155px;
$ico-linkedin-width: 16px;
$ico-linkedin-height: 16px;
$ico-linkedin-total-width: 188px;
$ico-linkedin-total-height: 171px;
$ico-linkedin-image: 'img/spritesheet.png';
$ico-linkedin: (32px, 155px, -32px, -155px, 16px, 16px, 188px, 171px, 'img/spritesheet.png', 'ico-linkedin', );
$ico-noguera-b-name: 'ico-noguera-b';
$ico-noguera-b-x: 46px;
$ico-noguera-b-y: 46px;
$ico-noguera-b-offset-x: -46px;
$ico-noguera-b-offset-y: -46px;
$ico-noguera-b-width: 46px;
$ico-noguera-b-height: 35px;
$ico-noguera-b-total-width: 188px;
$ico-noguera-b-total-height: 171px;
$ico-noguera-b-image: 'img/spritesheet.png';
$ico-noguera-b: (46px, 46px, -46px, -46px, 46px, 35px, 188px, 171px, 'img/spritesheet.png', 'ico-noguera-b', );
$ico-noguera-name: 'ico-noguera';
$ico-noguera-x: 0px;
$ico-noguera-y: 46px;
$ico-noguera-offset-x: 0px;
$ico-noguera-offset-y: -46px;
$ico-noguera-width: 46px;
$ico-noguera-height: 35px;
$ico-noguera-total-width: 188px;
$ico-noguera-total-height: 171px;
$ico-noguera-image: 'img/spritesheet.png';
$ico-noguera: (0px, 46px, 0px, -46px, 46px, 35px, 188px, 171px, 'img/spritesheet.png', 'ico-noguera', );
$ico-pla-urgell-b-name: 'ico-pla-urgell-b';
$ico-pla-urgell-b-x: 105px;
$ico-pla-urgell-b-y: 81px;
$ico-pla-urgell-b-offset-x: -105px;
$ico-pla-urgell-b-offset-y: -81px;
$ico-pla-urgell-b-width: 23px;
$ico-pla-urgell-b-height: 16px;
$ico-pla-urgell-b-total-width: 188px;
$ico-pla-urgell-b-total-height: 171px;
$ico-pla-urgell-b-image: 'img/spritesheet.png';
$ico-pla-urgell-b: (105px, 81px, -105px, -81px, 23px, 16px, 188px, 171px, 'img/spritesheet.png', 'ico-pla-urgell-b', );
$ico-pla-urgell-name: 'ico-pla-urgell';
$ico-pla-urgell-x: 56px;
$ico-pla-urgell-y: 128px;
$ico-pla-urgell-offset-x: -56px;
$ico-pla-urgell-offset-y: -128px;
$ico-pla-urgell-width: 23px;
$ico-pla-urgell-height: 16px;
$ico-pla-urgell-total-width: 188px;
$ico-pla-urgell-total-height: 171px;
$ico-pla-urgell-image: 'img/spritesheet.png';
$ico-pla-urgell: (56px, 128px, -56px, -128px, 23px, 16px, 188px, 171px, 'img/spritesheet.png', 'ico-pla-urgell', );
$ico-segarra-b-name: 'ico-segarra-b';
$ico-segarra-b-x: 163px;
$ico-segarra-b-y: 29px;
$ico-segarra-b-offset-x: -163px;
$ico-segarra-b-offset-y: -29px;
$ico-segarra-b-width: 22px;
$ico-segarra-b-height: 30px;
$ico-segarra-b-total-width: 188px;
$ico-segarra-b-total-height: 171px;
$ico-segarra-b-image: 'img/spritesheet.png';
$ico-segarra-b: (163px, 29px, -163px, -29px, 22px, 30px, 188px, 171px, 'img/spritesheet.png', 'ico-segarra-b', );
$ico-segarra-name: 'ico-segarra';
$ico-segarra-x: 163px;
$ico-segarra-y: 59px;
$ico-segarra-offset-x: -163px;
$ico-segarra-offset-y: -59px;
$ico-segarra-width: 22px;
$ico-segarra-height: 30px;
$ico-segarra-total-width: 188px;
$ico-segarra-total-height: 171px;
$ico-segarra-image: 'img/spritesheet.png';
$ico-segarra: (163px, 59px, -163px, -59px, 22px, 30px, 188px, 171px, 'img/spritesheet.png', 'ico-segarra', );
$ico-segria-b-name: 'ico-segria-b';
$ico-segria-b-x: 0px;
$ico-segria-b-y: 81px;
$ico-segria-b-offset-x: 0px;
$ico-segria-b-offset-y: -81px;
$ico-segria-b-width: 31px;
$ico-segria-b-height: 47px;
$ico-segria-b-total-width: 188px;
$ico-segria-b-total-height: 171px;
$ico-segria-b-image: 'img/spritesheet.png';
$ico-segria-b: (0px, 81px, 0px, -81px, 31px, 47px, 188px, 171px, 'img/spritesheet.png', 'ico-segria-b', );
$ico-segria-name: 'ico-segria';
$ico-segria-x: 31px;
$ico-segria-y: 81px;
$ico-segria-offset-x: -31px;
$ico-segria-offset-y: -81px;
$ico-segria-width: 31px;
$ico-segria-height: 47px;
$ico-segria-total-width: 188px;
$ico-segria-total-height: 171px;
$ico-segria-image: 'img/spritesheet.png';
$ico-segria: (31px, 81px, -31px, -81px, 31px, 47px, 188px, 171px, 'img/spritesheet.png', 'ico-segria', );
$ico-twitter-espais-name: 'ico-twitter-espais';
$ico-twitter-espais-x: 131px;
$ico-twitter-espais-y: 128px;
$ico-twitter-espais-offset-x: -131px;
$ico-twitter-espais-offset-y: -128px;
$ico-twitter-espais-width: 14px;
$ico-twitter-espais-height: 12px;
$ico-twitter-espais-total-width: 188px;
$ico-twitter-espais-total-height: 171px;
$ico-twitter-espais-image: 'img/spritesheet.png';
$ico-twitter-espais: (131px, 128px, -131px, -128px, 14px, 12px, 188px, 171px, 'img/spritesheet.png', 'ico-twitter-espais', );
$ico-twitter-name: 'ico-twitter';
$ico-twitter-x: 79px;
$ico-twitter-y: 128px;
$ico-twitter-offset-x: -79px;
$ico-twitter-offset-y: -128px;
$ico-twitter-width: 18px;
$ico-twitter-height: 16px;
$ico-twitter-total-width: 188px;
$ico-twitter-total-height: 171px;
$ico-twitter-image: 'img/spritesheet.png';
$ico-twitter: (79px, 128px, -79px, -128px, 18px, 16px, 188px, 171px, 'img/spritesheet.png', 'ico-twitter', );
$ico-urgell-b-name: 'ico-urgell-b';
$ico-urgell-b-x: 163px;
$ico-urgell-b-y: 121px;
$ico-urgell-b-offset-x: -163px;
$ico-urgell-b-offset-y: -121px;
$ico-urgell-b-width: 16px;
$ico-urgell-b-height: 32px;
$ico-urgell-b-total-width: 188px;
$ico-urgell-b-total-height: 171px;
$ico-urgell-b-image: 'img/spritesheet.png';
$ico-urgell-b: (163px, 121px, -163px, -121px, 16px, 32px, 188px, 171px, 'img/spritesheet.png', 'ico-urgell-b', );
$ico-urgell-name: 'ico-urgell';
$ico-urgell-x: 163px;
$ico-urgell-y: 89px;
$ico-urgell-offset-x: -163px;
$ico-urgell-offset-y: -89px;
$ico-urgell-width: 16px;
$ico-urgell-height: 32px;
$ico-urgell-total-width: 188px;
$ico-urgell-total-height: 171px;
$ico-urgell-image: 'img/spritesheet.png';
$ico-urgell: (163px, 89px, -163px, -89px, 16px, 32px, 188px, 171px, 'img/spritesheet.png', 'ico-urgell', );
$ico-youtube-espais-name: 'ico-youtube-espais';
$ico-youtube-espais-x: 116px;
$ico-youtube-espais-y: 128px;
$ico-youtube-espais-offset-x: -116px;
$ico-youtube-espais-offset-y: -128px;
$ico-youtube-espais-width: 15px;
$ico-youtube-espais-height: 12px;
$ico-youtube-espais-total-width: 188px;
$ico-youtube-espais-total-height: 171px;
$ico-youtube-espais-image: 'img/spritesheet.png';
$ico-youtube-espais: (116px, 128px, -116px, -128px, 15px, 12px, 188px, 171px, 'img/spritesheet.png', 'ico-youtube-espais', );
$ico-youtube-name: 'ico-youtube';
$ico-youtube-x: 97px;
$ico-youtube-y: 128px;
$ico-youtube-offset-x: -97px;
$ico-youtube-offset-y: -128px;
$ico-youtube-width: 19px;
$ico-youtube-height: 15px;
$ico-youtube-total-width: 188px;
$ico-youtube-total-height: 171px;
$ico-youtube-image: 'img/spritesheet.png';
$ico-youtube: (97px, 128px, -97px, -128px, 19px, 15px, 188px, 171px, 'img/spritesheet.png', 'ico-youtube', );
$icon-download-name: 'icon-download';
$icon-download-x: 62px;
$icon-download-y: 105px;
$icon-download-offset-x: -62px;
$icon-download-offset-y: -105px;
$icon-download-width: 30px;
$icon-download-height: 20px;
$icon-download-total-width: 188px;
$icon-download-total-height: 171px;
$icon-download-image: 'img/spritesheet.png';
$icon-download: (62px, 105px, -62px, -105px, 30px, 20px, 188px, 171px, 'img/spritesheet.png', 'icon-download', );
$icon-enllacos-name: 'icon-enllacos';
$icon-enllacos-x: 121px;
$icon-enllacos-y: 105px;
$icon-enllacos-offset-x: -121px;
$icon-enllacos-offset-y: -105px;
$icon-enllacos-width: 11px;
$icon-enllacos-height: 18px;
$icon-enllacos-total-width: 188px;
$icon-enllacos-total-height: 171px;
$icon-enllacos-image: 'img/spritesheet.png';
$icon-enllacos: (121px, 105px, -121px, -105px, 11px, 18px, 188px, 171px, 'img/spritesheet.png', 'icon-enllacos', );
$icon-menu-small-name: 'icon-menu-small';
$icon-menu-small-x: 92px;
$icon-menu-small-y: 0px;
$icon-menu-small-offset-x: -92px;
$icon-menu-small-offset-y: 0px;
$icon-menu-small-width: 40px;
$icon-menu-small-height: 40px;
$icon-menu-small-total-width: 188px;
$icon-menu-small-total-height: 171px;
$icon-menu-small-image: 'img/spritesheet.png';
$icon-menu-small: (92px, 0px, -92px, 0px, 40px, 40px, 188px, 171px, 'img/spritesheet.png', 'icon-menu-small', );
$icon-pdf-name: 'icon-pdf';
$icon-pdf-x: 163px;
$icon-pdf-y: 0px;
$icon-pdf-offset-x: -163px;
$icon-pdf-offset-y: 0px;
$icon-pdf-width: 25px;
$icon-pdf-height: 29px;
$icon-pdf-total-width: 188px;
$icon-pdf-total-height: 171px;
$icon-pdf-image: 'img/spritesheet.png';
$icon-pdf: (163px, 0px, -163px, 0px, 25px, 29px, 188px, 171px, 'img/spritesheet.png', 'icon-pdf', );
$menu-responsive-name: 'menu-responsive';
$menu-responsive-x: 132px;
$menu-responsive-y: 106px;
$menu-responsive-offset-x: -132px;
$menu-responsive-offset-y: -106px;
$menu-responsive-width: 31px;
$menu-responsive-height: 22px;
$menu-responsive-total-width: 188px;
$menu-responsive-total-height: 171px;
$menu-responsive-image: 'img/spritesheet.png';
$menu-responsive: (132px, 106px, -132px, -106px, 31px, 22px, 188px, 171px, 'img/spritesheet.png', 'menu-responsive', );
$plegar-name: 'plegar';
$plegar-x: 46px;
$plegar-y: 0px;
$plegar-offset-x: -46px;
$plegar-offset-y: 0px;
$plegar-width: 46px;
$plegar-height: 46px;
$plegar-total-width: 188px;
$plegar-total-height: 171px;
$plegar-image: 'img/spritesheet.png';
$plegar: (46px, 0px, -46px, 0px, 46px, 46px, 188px, 171px, 'img/spritesheet.png', 'plegar', );
$top-name: 'top';
$top-x: 62px;
$top-y: 81px;
$top-offset-x: -62px;
$top-offset-y: -81px;
$top-width: 43px;
$top-height: 24px;
$top-total-width: 188px;
$top-total-height: 171px;
$top-image: 'img/spritesheet.png';
$top: (62px, 81px, -62px, -81px, 43px, 24px, 188px, 171px, 'img/spritesheet.png', 'top', );
$compartir-email-2x-name: 'compartir-email@2x';
$compartir-email-2x-x: 184px;
$compartir-email-2x-y: 210px;
$compartir-email-2x-offset-x: -184px;
$compartir-email-2x-offset-y: -210px;
$compartir-email-2x-width: 58px;
$compartir-email-2x-height: 38px;
$compartir-email-2x-total-width: 376px;
$compartir-email-2x-total-height: 342px;
$compartir-email-2x-image: 'img/spritesheet@2x.png';
$compartir-email-2x: (184px, 210px, -184px, -210px, 58px, 38px, 376px, 342px, 'img/spritesheet@2x.png', 'compartir-email@2x', );
$compartir-facebook-2x-name: 'compartir-facebook@2x';
$compartir-facebook-2x-x: 0px;
$compartir-facebook-2x-y: 256px;
$compartir-facebook-2x-offset-x: 0px;
$compartir-facebook-2x-offset-y: -256px;
$compartir-facebook-2x-width: 54px;
$compartir-facebook-2x-height: 54px;
$compartir-facebook-2x-total-width: 376px;
$compartir-facebook-2x-total-height: 342px;
$compartir-facebook-2x-image: 'img/spritesheet@2x.png';
$compartir-facebook-2x: (0px, 256px, 0px, -256px, 54px, 54px, 376px, 342px, 'img/spritesheet@2x.png', 'compartir-facebook@2x', );
$compartir-link-2x-name: 'compartir-link@2x';
$compartir-link-2x-x: 264px;
$compartir-link-2x-y: 0px;
$compartir-link-2x-offset-x: -264px;
$compartir-link-2x-offset-y: 0px;
$compartir-link-2x-width: 62px;
$compartir-link-2x-height: 64px;
$compartir-link-2x-total-width: 376px;
$compartir-link-2x-total-height: 342px;
$compartir-link-2x-image: 'img/spritesheet@2x.png';
$compartir-link-2x: (264px, 0px, -264px, 0px, 62px, 64px, 376px, 342px, 'img/spritesheet@2x.png', 'compartir-link@2x', );
$compartir-telegram-2x-name: 'compartir-telegram@2x';
$compartir-telegram-2x-x: 54px;
$compartir-telegram-2x-y: 256px;
$compartir-telegram-2x-offset-x: -54px;
$compartir-telegram-2x-offset-y: -256px;
$compartir-telegram-2x-width: 58px;
$compartir-telegram-2x-height: 50px;
$compartir-telegram-2x-total-width: 376px;
$compartir-telegram-2x-total-height: 342px;
$compartir-telegram-2x-image: 'img/spritesheet@2x.png';
$compartir-telegram-2x: (54px, 256px, -54px, -256px, 58px, 50px, 376px, 342px, 'img/spritesheet@2x.png', 'compartir-telegram@2x', );
$compartir-twitter-2x-name: 'compartir-twitter@2x';
$compartir-twitter-2x-x: 264px;
$compartir-twitter-2x-y: 64px;
$compartir-twitter-2x-offset-x: -264px;
$compartir-twitter-2x-offset-y: -64px;
$compartir-twitter-2x-width: 60px;
$compartir-twitter-2x-height: 52px;
$compartir-twitter-2x-total-width: 376px;
$compartir-twitter-2x-total-height: 342px;
$compartir-twitter-2x-image: 'img/spritesheet@2x.png';
$compartir-twitter-2x: (264px, 64px, -264px, -64px, 60px, 52px, 376px, 342px, 'img/spritesheet@2x.png', 'compartir-twitter@2x', );
$compartir-whatsapp-2x-name: 'compartir-whatsapp@2x';
$compartir-whatsapp-2x-x: 184px;
$compartir-whatsapp-2x-y: 80px;
$compartir-whatsapp-2x-offset-x: -184px;
$compartir-whatsapp-2x-offset-y: -80px;
$compartir-whatsapp-2x-width: 66px;
$compartir-whatsapp-2x-height: 66px;
$compartir-whatsapp-2x-total-width: 376px;
$compartir-whatsapp-2x-total-height: 342px;
$compartir-whatsapp-2x-image: 'img/spritesheet@2x.png';
$compartir-whatsapp-2x: (184px, 80px, -184px, -80px, 66px, 66px, 376px, 342px, 'img/spritesheet@2x.png', 'compartir-whatsapp@2x', );
$desplegar-2x-name: 'desplegar@2x';
$desplegar-2x-x: 0px;
$desplegar-2x-y: 0px;
$desplegar-2x-offset-x: 0px;
$desplegar-2x-offset-y: 0px;
$desplegar-2x-width: 92px;
$desplegar-2x-height: 92px;
$desplegar-2x-total-width: 376px;
$desplegar-2x-total-height: 342px;
$desplegar-2x-image: 'img/spritesheet@2x.png';
$desplegar-2x: (0px, 0px, 0px, 0px, 92px, 92px, 376px, 342px, 'img/spritesheet@2x.png', 'desplegar@2x', );
$ico-facebook-espais-2x-name: 'ico-facebook-espais@2x';
$ico-facebook-espais-2x-x: 96px;
$ico-facebook-espais-2x-y: 310px;
$ico-facebook-espais-2x-offset-x: -96px;
$ico-facebook-espais-2x-offset-y: -310px;
$ico-facebook-espais-2x-width: 24px;
$ico-facebook-espais-2x-height: 24px;
$ico-facebook-espais-2x-total-width: 376px;
$ico-facebook-espais-2x-total-height: 342px;
$ico-facebook-espais-2x-image: 'img/spritesheet@2x.png';
$ico-facebook-espais-2x: (96px, 310px, -96px, -310px, 24px, 24px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-facebook-espais@2x', );
$ico-facebook-2x-name: 'ico-facebook@2x';
$ico-facebook-2x-x: 32px;
$ico-facebook-2x-y: 310px;
$ico-facebook-2x-offset-x: -32px;
$ico-facebook-2x-offset-y: -310px;
$ico-facebook-2x-width: 32px;
$ico-facebook-2x-height: 32px;
$ico-facebook-2x-total-width: 376px;
$ico-facebook-2x-total-height: 342px;
$ico-facebook-2x-image: 'img/spritesheet@2x.png';
$ico-facebook-2x: (32px, 310px, -32px, -310px, 32px, 32px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-facebook@2x', );
$ico-garrigues-b-2x-name: 'ico-garrigues-b@2x';
$ico-garrigues-b-2x-x: 264px;
$ico-garrigues-b-2x-y: 116px;
$ico-garrigues-b-2x-offset-x: -264px;
$ico-garrigues-b-2x-offset-y: -116px;
$ico-garrigues-b-2x-width: 62px;
$ico-garrigues-b-2x-height: 48px;
$ico-garrigues-b-2x-total-width: 376px;
$ico-garrigues-b-2x-total-height: 342px;
$ico-garrigues-b-2x-image: 'img/spritesheet@2x.png';
$ico-garrigues-b-2x: (264px, 116px, -264px, -116px, 62px, 48px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-garrigues-b@2x', );
$ico-garrigues-2x-name: 'ico-garrigues@2x';
$ico-garrigues-2x-x: 264px;
$ico-garrigues-2x-y: 164px;
$ico-garrigues-2x-offset-x: -264px;
$ico-garrigues-2x-offset-y: -164px;
$ico-garrigues-2x-width: 62px;
$ico-garrigues-2x-height: 48px;
$ico-garrigues-2x-total-width: 376px;
$ico-garrigues-2x-total-height: 342px;
$ico-garrigues-2x-image: 'img/spritesheet@2x.png';
$ico-garrigues-2x: (264px, 164px, -264px, -164px, 62px, 48px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-garrigues@2x', );
$ico-instagram-espais-2x-name: 'ico-instagram-espais@2x';
$ico-instagram-espais-2x-x: 290px;
$ico-instagram-espais-2x-y: 256px;
$ico-instagram-espais-2x-offset-x: -290px;
$ico-instagram-espais-2x-offset-y: -256px;
$ico-instagram-espais-2x-width: 24px;
$ico-instagram-espais-2x-height: 24px;
$ico-instagram-espais-2x-total-width: 376px;
$ico-instagram-espais-2x-total-height: 342px;
$ico-instagram-espais-2x-image: 'img/spritesheet@2x.png';
$ico-instagram-espais-2x: (290px, 256px, -290px, -256px, 24px, 24px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-instagram-espais@2x', );
$ico-instagram-2x-name: 'ico-instagram@2x';
$ico-instagram-2x-x: 0px;
$ico-instagram-2x-y: 310px;
$ico-instagram-2x-offset-x: 0px;
$ico-instagram-2x-offset-y: -310px;
$ico-instagram-2x-width: 32px;
$ico-instagram-2x-height: 32px;
$ico-instagram-2x-total-width: 376px;
$ico-instagram-2x-total-height: 342px;
$ico-instagram-2x-image: 'img/spritesheet@2x.png';
$ico-instagram-2x: (0px, 310px, 0px, -310px, 32px, 32px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-instagram@2x', );
$ico-linkedin-2x-name: 'ico-linkedin@2x';
$ico-linkedin-2x-x: 64px;
$ico-linkedin-2x-y: 310px;
$ico-linkedin-2x-offset-x: -64px;
$ico-linkedin-2x-offset-y: -310px;
$ico-linkedin-2x-width: 32px;
$ico-linkedin-2x-height: 32px;
$ico-linkedin-2x-total-width: 376px;
$ico-linkedin-2x-total-height: 342px;
$ico-linkedin-2x-image: 'img/spritesheet@2x.png';
$ico-linkedin-2x: (64px, 310px, -64px, -310px, 32px, 32px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-linkedin@2x', );
$ico-noguera-b-2x-name: 'ico-noguera-b@2x';
$ico-noguera-b-2x-x: 92px;
$ico-noguera-b-2x-y: 92px;
$ico-noguera-b-2x-offset-x: -92px;
$ico-noguera-b-2x-offset-y: -92px;
$ico-noguera-b-2x-width: 92px;
$ico-noguera-b-2x-height: 70px;
$ico-noguera-b-2x-total-width: 376px;
$ico-noguera-b-2x-total-height: 342px;
$ico-noguera-b-2x-image: 'img/spritesheet@2x.png';
$ico-noguera-b-2x: (92px, 92px, -92px, -92px, 92px, 70px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-noguera-b@2x', );
$ico-noguera-2x-name: 'ico-noguera@2x';
$ico-noguera-2x-x: 0px;
$ico-noguera-2x-y: 92px;
$ico-noguera-2x-offset-x: 0px;
$ico-noguera-2x-offset-y: -92px;
$ico-noguera-2x-width: 92px;
$ico-noguera-2x-height: 70px;
$ico-noguera-2x-total-width: 376px;
$ico-noguera-2x-total-height: 342px;
$ico-noguera-2x-image: 'img/spritesheet@2x.png';
$ico-noguera-2x: (0px, 92px, 0px, -92px, 92px, 70px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-noguera@2x', );
$ico-pla-urgell-b-2x-name: 'ico-pla-urgell-b@2x';
$ico-pla-urgell-b-2x-x: 210px;
$ico-pla-urgell-b-2x-y: 162px;
$ico-pla-urgell-b-2x-offset-x: -210px;
$ico-pla-urgell-b-2x-offset-y: -162px;
$ico-pla-urgell-b-2x-width: 46px;
$ico-pla-urgell-b-2x-height: 32px;
$ico-pla-urgell-b-2x-total-width: 376px;
$ico-pla-urgell-b-2x-total-height: 342px;
$ico-pla-urgell-b-2x-image: 'img/spritesheet@2x.png';
$ico-pla-urgell-b-2x: (210px, 162px, -210px, -162px, 46px, 32px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-pla-urgell-b@2x', );
$ico-pla-urgell-2x-name: 'ico-pla-urgell@2x';
$ico-pla-urgell-2x-x: 112px;
$ico-pla-urgell-2x-y: 256px;
$ico-pla-urgell-2x-offset-x: -112px;
$ico-pla-urgell-2x-offset-y: -256px;
$ico-pla-urgell-2x-width: 46px;
$ico-pla-urgell-2x-height: 32px;
$ico-pla-urgell-2x-total-width: 376px;
$ico-pla-urgell-2x-total-height: 342px;
$ico-pla-urgell-2x-image: 'img/spritesheet@2x.png';
$ico-pla-urgell-2x: (112px, 256px, -112px, -256px, 46px, 32px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-pla-urgell@2x', );
$ico-segarra-b-2x-name: 'ico-segarra-b@2x';
$ico-segarra-b-2x-x: 326px;
$ico-segarra-b-2x-y: 58px;
$ico-segarra-b-2x-offset-x: -326px;
$ico-segarra-b-2x-offset-y: -58px;
$ico-segarra-b-2x-width: 44px;
$ico-segarra-b-2x-height: 60px;
$ico-segarra-b-2x-total-width: 376px;
$ico-segarra-b-2x-total-height: 342px;
$ico-segarra-b-2x-image: 'img/spritesheet@2x.png';
$ico-segarra-b-2x: (326px, 58px, -326px, -58px, 44px, 60px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-segarra-b@2x', );
$ico-segarra-2x-name: 'ico-segarra@2x';
$ico-segarra-2x-x: 326px;
$ico-segarra-2x-y: 118px;
$ico-segarra-2x-offset-x: -326px;
$ico-segarra-2x-offset-y: -118px;
$ico-segarra-2x-width: 44px;
$ico-segarra-2x-height: 60px;
$ico-segarra-2x-total-width: 376px;
$ico-segarra-2x-total-height: 342px;
$ico-segarra-2x-image: 'img/spritesheet@2x.png';
$ico-segarra-2x: (326px, 118px, -326px, -118px, 44px, 60px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-segarra@2x', );
$ico-segria-b-2x-name: 'ico-segria-b@2x';
$ico-segria-b-2x-x: 0px;
$ico-segria-b-2x-y: 162px;
$ico-segria-b-2x-offset-x: 0px;
$ico-segria-b-2x-offset-y: -162px;
$ico-segria-b-2x-width: 62px;
$ico-segria-b-2x-height: 94px;
$ico-segria-b-2x-total-width: 376px;
$ico-segria-b-2x-total-height: 342px;
$ico-segria-b-2x-image: 'img/spritesheet@2x.png';
$ico-segria-b-2x: (0px, 162px, 0px, -162px, 62px, 94px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-segria-b@2x', );
$ico-segria-2x-name: 'ico-segria@2x';
$ico-segria-2x-x: 62px;
$ico-segria-2x-y: 162px;
$ico-segria-2x-offset-x: -62px;
$ico-segria-2x-offset-y: -162px;
$ico-segria-2x-width: 62px;
$ico-segria-2x-height: 94px;
$ico-segria-2x-total-width: 376px;
$ico-segria-2x-total-height: 342px;
$ico-segria-2x-image: 'img/spritesheet@2x.png';
$ico-segria-2x: (62px, 162px, -62px, -162px, 62px, 94px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-segria@2x', );
$ico-twitter-espais-2x-name: 'ico-twitter-espais@2x';
$ico-twitter-espais-2x-x: 262px;
$ico-twitter-espais-2x-y: 256px;
$ico-twitter-espais-2x-offset-x: -262px;
$ico-twitter-espais-2x-offset-y: -256px;
$ico-twitter-espais-2x-width: 28px;
$ico-twitter-espais-2x-height: 24px;
$ico-twitter-espais-2x-total-width: 376px;
$ico-twitter-espais-2x-total-height: 342px;
$ico-twitter-espais-2x-image: 'img/spritesheet@2x.png';
$ico-twitter-espais-2x: (262px, 256px, -262px, -256px, 28px, 24px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-twitter-espais@2x', );
$ico-twitter-2x-name: 'ico-twitter@2x';
$ico-twitter-2x-x: 158px;
$ico-twitter-2x-y: 256px;
$ico-twitter-2x-offset-x: -158px;
$ico-twitter-2x-offset-y: -256px;
$ico-twitter-2x-width: 36px;
$ico-twitter-2x-height: 32px;
$ico-twitter-2x-total-width: 376px;
$ico-twitter-2x-total-height: 342px;
$ico-twitter-2x-image: 'img/spritesheet@2x.png';
$ico-twitter-2x: (158px, 256px, -158px, -256px, 36px, 32px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-twitter@2x', );
$ico-urgell-b-2x-name: 'ico-urgell-b@2x';
$ico-urgell-b-2x-x: 326px;
$ico-urgell-b-2x-y: 242px;
$ico-urgell-b-2x-offset-x: -326px;
$ico-urgell-b-2x-offset-y: -242px;
$ico-urgell-b-2x-width: 32px;
$ico-urgell-b-2x-height: 64px;
$ico-urgell-b-2x-total-width: 376px;
$ico-urgell-b-2x-total-height: 342px;
$ico-urgell-b-2x-image: 'img/spritesheet@2x.png';
$ico-urgell-b-2x: (326px, 242px, -326px, -242px, 32px, 64px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-urgell-b@2x', );
$ico-urgell-2x-name: 'ico-urgell@2x';
$ico-urgell-2x-x: 326px;
$ico-urgell-2x-y: 178px;
$ico-urgell-2x-offset-x: -326px;
$ico-urgell-2x-offset-y: -178px;
$ico-urgell-2x-width: 32px;
$ico-urgell-2x-height: 64px;
$ico-urgell-2x-total-width: 376px;
$ico-urgell-2x-total-height: 342px;
$ico-urgell-2x-image: 'img/spritesheet@2x.png';
$ico-urgell-2x: (326px, 178px, -326px, -178px, 32px, 64px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-urgell@2x', );
$ico-youtube-espais-2x-name: 'ico-youtube-espais@2x';
$ico-youtube-espais-2x-x: 232px;
$ico-youtube-espais-2x-y: 256px;
$ico-youtube-espais-2x-offset-x: -232px;
$ico-youtube-espais-2x-offset-y: -256px;
$ico-youtube-espais-2x-width: 30px;
$ico-youtube-espais-2x-height: 24px;
$ico-youtube-espais-2x-total-width: 376px;
$ico-youtube-espais-2x-total-height: 342px;
$ico-youtube-espais-2x-image: 'img/spritesheet@2x.png';
$ico-youtube-espais-2x: (232px, 256px, -232px, -256px, 30px, 24px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-youtube-espais@2x', );
$ico-youtube-2x-name: 'ico-youtube@2x';
$ico-youtube-2x-x: 194px;
$ico-youtube-2x-y: 256px;
$ico-youtube-2x-offset-x: -194px;
$ico-youtube-2x-offset-y: -256px;
$ico-youtube-2x-width: 38px;
$ico-youtube-2x-height: 30px;
$ico-youtube-2x-total-width: 376px;
$ico-youtube-2x-total-height: 342px;
$ico-youtube-2x-image: 'img/spritesheet@2x.png';
$ico-youtube-2x: (194px, 256px, -194px, -256px, 38px, 30px, 376px, 342px, 'img/spritesheet@2x.png', 'ico-youtube@2x', );
$icon-download-2x-name: 'icon-download@2x';
$icon-download-2x-x: 124px;
$icon-download-2x-y: 210px;
$icon-download-2x-offset-x: -124px;
$icon-download-2x-offset-y: -210px;
$icon-download-2x-width: 60px;
$icon-download-2x-height: 40px;
$icon-download-2x-total-width: 376px;
$icon-download-2x-total-height: 342px;
$icon-download-2x-image: 'img/spritesheet@2x.png';
$icon-download-2x: (124px, 210px, -124px, -210px, 60px, 40px, 376px, 342px, 'img/spritesheet@2x.png', 'icon-download@2x', );
$icon-enllacos-2x-name: 'icon-enllacos@2x';
$icon-enllacos-2x-x: 242px;
$icon-enllacos-2x-y: 210px;
$icon-enllacos-2x-offset-x: -242px;
$icon-enllacos-2x-offset-y: -210px;
$icon-enllacos-2x-width: 22px;
$icon-enllacos-2x-height: 36px;
$icon-enllacos-2x-total-width: 376px;
$icon-enllacos-2x-total-height: 342px;
$icon-enllacos-2x-image: 'img/spritesheet@2x.png';
$icon-enllacos-2x: (242px, 210px, -242px, -210px, 22px, 36px, 376px, 342px, 'img/spritesheet@2x.png', 'icon-enllacos@2x', );
$icon-menu-small-2x-name: 'icon-menu-small@2x';
$icon-menu-small-2x-x: 184px;
$icon-menu-small-2x-y: 0px;
$icon-menu-small-2x-offset-x: -184px;
$icon-menu-small-2x-offset-y: 0px;
$icon-menu-small-2x-width: 80px;
$icon-menu-small-2x-height: 80px;
$icon-menu-small-2x-total-width: 376px;
$icon-menu-small-2x-total-height: 342px;
$icon-menu-small-2x-image: 'img/spritesheet@2x.png';
$icon-menu-small-2x: (184px, 0px, -184px, 0px, 80px, 80px, 376px, 342px, 'img/spritesheet@2x.png', 'icon-menu-small@2x', );
$icon-pdf-2x-name: 'icon-pdf@2x';
$icon-pdf-2x-x: 326px;
$icon-pdf-2x-y: 0px;
$icon-pdf-2x-offset-x: -326px;
$icon-pdf-2x-offset-y: 0px;
$icon-pdf-2x-width: 50px;
$icon-pdf-2x-height: 58px;
$icon-pdf-2x-total-width: 376px;
$icon-pdf-2x-total-height: 342px;
$icon-pdf-2x-image: 'img/spritesheet@2x.png';
$icon-pdf-2x: (326px, 0px, -326px, 0px, 50px, 58px, 376px, 342px, 'img/spritesheet@2x.png', 'icon-pdf@2x', );
$menu-responsive-2x-name: 'menu-responsive@2x';
$menu-responsive-2x-x: 264px;
$menu-responsive-2x-y: 212px;
$menu-responsive-2x-offset-x: -264px;
$menu-responsive-2x-offset-y: -212px;
$menu-responsive-2x-width: 62px;
$menu-responsive-2x-height: 44px;
$menu-responsive-2x-total-width: 376px;
$menu-responsive-2x-total-height: 342px;
$menu-responsive-2x-image: 'img/spritesheet@2x.png';
$menu-responsive-2x: (264px, 212px, -264px, -212px, 62px, 44px, 376px, 342px, 'img/spritesheet@2x.png', 'menu-responsive@2x', );
$plegar-2x-name: 'plegar@2x';
$plegar-2x-x: 92px;
$plegar-2x-y: 0px;
$plegar-2x-offset-x: -92px;
$plegar-2x-offset-y: 0px;
$plegar-2x-width: 92px;
$plegar-2x-height: 92px;
$plegar-2x-total-width: 376px;
$plegar-2x-total-height: 342px;
$plegar-2x-image: 'img/spritesheet@2x.png';
$plegar-2x: (92px, 0px, -92px, 0px, 92px, 92px, 376px, 342px, 'img/spritesheet@2x.png', 'plegar@2x', );
$top-2x-name: 'top@2x';
$top-2x-x: 124px;
$top-2x-y: 162px;
$top-2x-offset-x: -124px;
$top-2x-offset-y: -162px;
$top-2x-width: 86px;
$top-2x-height: 48px;
$top-2x-total-width: 376px;
$top-2x-total-height: 342px;
$top-2x-image: 'img/spritesheet@2x.png';
$top-2x: (124px, 162px, -124px, -162px, 86px, 48px, 376px, 342px, 'img/spritesheet@2x.png', 'top@2x', );
$spritesheet-width: 188px;
$spritesheet-height: 171px;
$spritesheet-image: 'img/spritesheet.png';
$spritesheet-sprites: ($compartir-email, $compartir-facebook, $compartir-link, $compartir-telegram, $compartir-twitter, $compartir-whatsapp, $desplegar, $ico-facebook-espais, $ico-facebook, $ico-garrigues-b, $ico-garrigues, $ico-instagram-espais, $ico-instagram, $ico-linkedin, $ico-noguera-b, $ico-noguera, $ico-pla-urgell-b, $ico-pla-urgell, $ico-segarra-b, $ico-segarra, $ico-segria-b, $ico-segria, $ico-twitter-espais, $ico-twitter, $ico-urgell-b, $ico-urgell, $ico-youtube-espais, $ico-youtube, $icon-download, $icon-enllacos, $icon-menu-small, $icon-pdf, $menu-responsive, $plegar, $top, );
$spritesheet: (188px, 171px, 'img/spritesheet.png', $spritesheet-sprites, );
$retina-spritesheet-width: 376px;
$retina-spritesheet-height: 342px;
$retina-spritesheet-image: 'img/spritesheet@2x.png';
$retina-spritesheet-sprites: ($compartir-email-2x, $compartir-facebook-2x, $compartir-link-2x, $compartir-telegram-2x, $compartir-twitter-2x, $compartir-whatsapp-2x, $desplegar-2x, $ico-facebook-espais-2x, $ico-facebook-2x, $ico-garrigues-b-2x, $ico-garrigues-2x, $ico-instagram-espais-2x, $ico-instagram-2x, $ico-linkedin-2x, $ico-noguera-b-2x, $ico-noguera-2x, $ico-pla-urgell-b-2x, $ico-pla-urgell-2x, $ico-segarra-b-2x, $ico-segarra-2x, $ico-segria-b-2x, $ico-segria-2x, $ico-twitter-espais-2x, $ico-twitter-2x, $ico-urgell-b-2x, $ico-urgell-2x, $ico-youtube-espais-2x, $ico-youtube-2x, $icon-download-2x, $icon-enllacos-2x, $icon-menu-small-2x, $icon-pdf-2x, $menu-responsive-2x, $plegar-2x, $top-2x, );
$retina-spritesheet: (376px, 342px, 'img/spritesheet@2x.png', $retina-spritesheet-sprites, );

/*
These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.

The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
*/
$compartir-email-group-name: 'compartir-email';
$compartir-email-group: ('compartir-email', $compartir-email, $compartir-email-2x, );
$compartir-facebook-group-name: 'compartir-facebook';
$compartir-facebook-group: ('compartir-facebook', $compartir-facebook, $compartir-facebook-2x, );
$compartir-link-group-name: 'compartir-link';
$compartir-link-group: ('compartir-link', $compartir-link, $compartir-link-2x, );
$compartir-telegram-group-name: 'compartir-telegram';
$compartir-telegram-group: ('compartir-telegram', $compartir-telegram, $compartir-telegram-2x, );
$compartir-twitter-group-name: 'compartir-twitter';
$compartir-twitter-group: ('compartir-twitter', $compartir-twitter, $compartir-twitter-2x, );
$compartir-whatsapp-group-name: 'compartir-whatsapp';
$compartir-whatsapp-group: ('compartir-whatsapp', $compartir-whatsapp, $compartir-whatsapp-2x, );
$desplegar-group-name: 'desplegar';
$desplegar-group: ('desplegar', $desplegar, $desplegar-2x, );
$ico-facebook-espais-group-name: 'ico-facebook-espais';
$ico-facebook-espais-group: ('ico-facebook-espais', $ico-facebook-espais, $ico-facebook-espais-2x, );
$ico-facebook-group-name: 'ico-facebook';
$ico-facebook-group: ('ico-facebook', $ico-facebook, $ico-facebook-2x, );
$ico-garrigues-b-group-name: 'ico-garrigues-b';
$ico-garrigues-b-group: ('ico-garrigues-b', $ico-garrigues-b, $ico-garrigues-b-2x, );
$ico-garrigues-group-name: 'ico-garrigues';
$ico-garrigues-group: ('ico-garrigues', $ico-garrigues, $ico-garrigues-2x, );
$ico-instagram-espais-group-name: 'ico-instagram-espais';
$ico-instagram-espais-group: ('ico-instagram-espais', $ico-instagram-espais, $ico-instagram-espais-2x, );
$ico-instagram-group-name: 'ico-instagram';
$ico-instagram-group: ('ico-instagram', $ico-instagram, $ico-instagram-2x, );
$ico-linkedin-group-name: 'ico-linkedin';
$ico-linkedin-group: ('ico-linkedin', $ico-linkedin, $ico-linkedin-2x, );
$ico-noguera-b-group-name: 'ico-noguera-b';
$ico-noguera-b-group: ('ico-noguera-b', $ico-noguera-b, $ico-noguera-b-2x, );
$ico-noguera-group-name: 'ico-noguera';
$ico-noguera-group: ('ico-noguera', $ico-noguera, $ico-noguera-2x, );
$ico-pla-urgell-b-group-name: 'ico-pla-urgell-b';
$ico-pla-urgell-b-group: ('ico-pla-urgell-b', $ico-pla-urgell-b, $ico-pla-urgell-b-2x, );
$ico-pla-urgell-group-name: 'ico-pla-urgell';
$ico-pla-urgell-group: ('ico-pla-urgell', $ico-pla-urgell, $ico-pla-urgell-2x, );
$ico-segarra-b-group-name: 'ico-segarra-b';
$ico-segarra-b-group: ('ico-segarra-b', $ico-segarra-b, $ico-segarra-b-2x, );
$ico-segarra-group-name: 'ico-segarra';
$ico-segarra-group: ('ico-segarra', $ico-segarra, $ico-segarra-2x, );
$ico-segria-b-group-name: 'ico-segria-b';
$ico-segria-b-group: ('ico-segria-b', $ico-segria-b, $ico-segria-b-2x, );
$ico-segria-group-name: 'ico-segria';
$ico-segria-group: ('ico-segria', $ico-segria, $ico-segria-2x, );
$ico-twitter-espais-group-name: 'ico-twitter-espais';
$ico-twitter-espais-group: ('ico-twitter-espais', $ico-twitter-espais, $ico-twitter-espais-2x, );
$ico-twitter-group-name: 'ico-twitter';
$ico-twitter-group: ('ico-twitter', $ico-twitter, $ico-twitter-2x, );
$ico-urgell-b-group-name: 'ico-urgell-b';
$ico-urgell-b-group: ('ico-urgell-b', $ico-urgell-b, $ico-urgell-b-2x, );
$ico-urgell-group-name: 'ico-urgell';
$ico-urgell-group: ('ico-urgell', $ico-urgell, $ico-urgell-2x, );
$ico-youtube-espais-group-name: 'ico-youtube-espais';
$ico-youtube-espais-group: ('ico-youtube-espais', $ico-youtube-espais, $ico-youtube-espais-2x, );
$ico-youtube-group-name: 'ico-youtube';
$ico-youtube-group: ('ico-youtube', $ico-youtube, $ico-youtube-2x, );
$icon-download-group-name: 'icon-download';
$icon-download-group: ('icon-download', $icon-download, $icon-download-2x, );
$icon-enllacos-group-name: 'icon-enllacos';
$icon-enllacos-group: ('icon-enllacos', $icon-enllacos, $icon-enllacos-2x, );
$icon-menu-small-group-name: 'icon-menu-small';
$icon-menu-small-group: ('icon-menu-small', $icon-menu-small, $icon-menu-small-2x, );
$icon-pdf-group-name: 'icon-pdf';
$icon-pdf-group: ('icon-pdf', $icon-pdf, $icon-pdf-2x, );
$menu-responsive-group-name: 'menu-responsive';
$menu-responsive-group: ('menu-responsive', $menu-responsive, $menu-responsive-2x, );
$plegar-group-name: 'plegar';
$plegar-group: ('plegar', $plegar, $plegar-2x, );
$top-group-name: 'top';
$top-group: ('top', $top, $top-2x, );
$retina-groups: ($compartir-email-group, $compartir-facebook-group, $compartir-link-group, $compartir-telegram-group, $compartir-twitter-group, $compartir-whatsapp-group, $desplegar-group, $ico-facebook-espais-group, $ico-facebook-group, $ico-garrigues-b-group, $ico-garrigues-group, $ico-instagram-espais-group, $ico-instagram-group, $ico-linkedin-group, $ico-noguera-b-group, $ico-noguera-group, $ico-pla-urgell-b-group, $ico-pla-urgell-group, $ico-segarra-b-group, $ico-segarra-group, $ico-segria-b-group, $ico-segria-group, $ico-twitter-espais-group, $ico-twitter-group, $ico-urgell-b-group, $ico-urgell-group, $ico-youtube-espais-group, $ico-youtube-group, $icon-download-group, $icon-enllacos-group, $icon-menu-small-group, $icon-pdf-group, $menu-responsive-group, $plegar-group, $top-group, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
  It should be used with a "retina group" variable.

The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/

$icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );

.icon-home {
  @include retina-sprite($icon-home-group);
}
*/
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

/*
The `retina-sprites` mixin generates a CSS rule and media query for retina groups
  This yields the same output as CSS retina template but can be overridden in SCSS

@include retina-sprites($retina-groups);
*/
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
