$slick-font-path: '../fonts/';
$slick-dot-size: 15px;

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

.slick-list {
    .slick-loading & {
        background-image: url('img/ajax-loader.gif');
    }
}

.slick-next {
  right: 8px;

  @media #{$medium} {
    right: 20px;
  }
}

.slick-prev {
  z-index: 1;
  left: 8px;

  @media #{$medium} {
    left: 20px;
  }
}

.slick-prev,
.slick-next {
  @media #{$medium} {
    height: 30px;
    width: 30px;
  }

  @media #{$large} {
    height: 40px;
    width: 40px;
  }

  &:before {
    @media #{$medium} {
      font-size: 30px;
    }

    @media #{$large} {
      font-size: 40px;
    }
  }
  &:focus {
    outline: 1px dotted black;
  }
}
.slider{
  max-height: 496px;
  overflow: hidden;
  h2{
    padding-top: rem-calc(300);
  }
}

// dots
.slider.slick-slider {
    //margin-bottom: 60px;
    overflow: initial;
    h2{
      visibility: visible;
    }
}
.slick-dots {
  bottom: 32px;
   @media #{$medium-up} {
    bottom: 25px;
   }
   @media #{$large-up} {
    bottom: 32px;
   }
  li {
    &:before {
      display: none;
    }

    button {
      &:before {
          font-size: $slick-dot-size;
      }
    }
  }
}
@media only screen and (max-width: $small-breakpoint) {
   .slide h2 {
      padding-top: rem-calc(70);
   } 
   .slider {
      margin-top: rem-calc(-20);
   }
  }
.slide {
  background: $base no-repeat 50% 0;
  background-size: cover;
  text-align: center;
  height: 20em;

  @media #{$small-up} {
    height: 12em;

  }

  @media #{$medium-up} {
    min-height: rem-calc(355);
    h2 {
      padding-top: rem-calc(180);
    }
  }

  @media #{$large-up} {
    min-height: rem-calc(496);
    h2 {
      padding-top: rem-calc(280);
    }
  }
}

.slide-1 {
  background-image: url('img/foto-portada.png');
}

.slide-2 {
  background-image: url('img/foto-portada.png');
}

.slide-3 {
  background-image: url('img/foto-portada.png');
}

.slide-4 {
  background-image: url('img/foto-portada.png');
}

.slide-content {
  @include grid-column($columns:12);
  padding-left: rem-calc(30);
  padding-right: rem-calc(30);

  @media #{$medium} {
    @include grid-column($columns:9, $center:true);
  }

  @media #{$large} {
    @include grid-column($columns:8, $center:true);
  }

  h2 {
    text-transform: none;
    margin: 0;
    letter-spacing: 3px;
    font-family:  $font-family-sans-serif;
    font-size: rem-calc(22);
    font-weight: 900;
    color: #fff;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    @media #{$medium} {
      font-size: rem-calc(22);
    }

    @media #{$large} {
      font-size: rem-calc(22);
    }
  }

  p {
    color: $white;
    font-size: rem-calc(16);
    margin: 0 0 0;
    font-weight: normal;
    font-family:  $font-family-sans-serif;
    letter-spacing: 2.7px;

    @media #{$medium} {
      font-size: rem-calc(16);
    }

    @media #{$large} {
      font-size: rem-calc(16);
    }
  }

  a {
    //border-bottom: 1px solid $white;
    color: $white;
    text-decoration: none;

    &:hover {
      border: 0;
    }
  }
}

