.main-header {
  h1 {
    margin: rem-calc(34 0 18);

    @media #{$medium-up} {
      margin: rem-calc(34 0 21);
    }

    a:hover {
      border: 0;
    }

    img {
      width: 237px;
      margin-bottom: rem-calc(25);

      @media #{$small-up} {
        width: 293px;
      }

      @media #{$medium-up} {
        width: 360px;
      }

      @media #{$large-up} {
        width: 402px;
      }
    }
  }

  .lang,
  .tools {
    display: none;
  }
  .share {
    display: none;
  }

  @media #{$medium-up} {
    h1 {
      width: 40%;
      float: left;
    }

    .tools {
      display: block;
      width: 60%;
      float: right;
      text-align: right;
      padding-top: 38px;
    }

    .lang {
      display: inline-block;
      margin-bottom: 0;
      float: right;

      li {
        display: inline;

        &.active a {
          background: none;
          opacity: .6;

          &:hover {
            text-decoration: none;
            cursor: default;

            abbr {
              cursor: default;
            }
          }
        }
      }

      a {
        display: inline-block;
        padding: 0 0.5em;
        line-height: 32px;
        color: #4a4a4a;
        font-size: rem-calc(9);
        text-transform: uppercase;
        font-family:  $font-family-sans-serif;
        text-align: right;
        font-weight: bold;

        &:hover {
          border: 0;
          opacity: .6;

          abbr {
            cursor: pointer;
          }
        }
      }
    }

    .share {
      display: inline-block;
      margin: 44px 4px 0 6px;
      vertical-align: top;
      list-style: none;
      float: right;
      
      li {
        display: inline;
      }

      a {
        margin: 0 0 0 12px;
        display: inline-block;
        &:hover {
          opacity: .6;
        }
      }
    }
  }

}
.menu-trigger {
  display:none;
}
@media only screen and (max-width: $small-breakpoint) {
.menu-trigger {
  margin-top: rem-calc(-50);
  //top: 50px;
  //right: $column-gutter/2;
  z-index: 20;
  text-transform: uppercase;
  text-align: center;
  font-size: rem-calc(12);
  display: block;
  background-color: $secondary-color;
  width: 100%;
  height: rem-calc(74);
  margin-bottom: rem-calc(20);
  &:before {
    //@extend .menu-responsive;
    content: '';
    display: block;
    margin-bottom: 3px;
    margin-top: rem-calc(30)

  //@media #{$medium-up} {
    //display: none;
  
}
.menu-responsive {
  display: block;
  margin-top: rem-calc(25);
  position: absolute;
  left: 48%;
}
}
}