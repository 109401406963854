.contacte {
  form {
    max-width: rem-calc(460);
    margin: 0 auto;
    margin-top: rem-calc(36);
    label {
      text-transform: uppercase;
      text-align: left;
      font-size: rem-calc(12);
      letter-spacing: 2.6px;
      color: #4a4a4a;
      margin-bottom: rem-calc(5);
      margin-top: rem-calc(25);
    }
    input[type='text'], input[type='email'] {
      background-color: #eeeeee;
      height: rem-calc(48);
      border: none;
      box-shadow: none;
    }
    textarea {
      background-color: #eeeeee;
      border: none;
      box-shadow: none;
    }
    .miss {
      margin-top: rem-calc(15);
    }
    .button {
      background-color: transparent;
      border: solid 1px #94a100;
      border-radius: 5px;
      font-size: rem-calc(11);
      color: $secondary-color;
      text-transform: uppercase;
      height: rem-calc(31);
      text-align: center;
      padding-top: rem-calc(8);
      letter-spacing: 3.1px;
      width: rem-calc(175);
      margin-top: rem-calc(50);
      &:hover {
        border-color: #7A8400;
      }
  }
  a {
    color: $primary-color;
  }
  input#privacitat {
    margin-right: rem-calc(10);
    margin-top: rem-calc(30);
    height: rem-calc(13);

  }
  }
  .dadescontacte {
    margin-top: rem-calc(90);
  p {
    font-size: rem-calc(14);
    color: #4a4a4a;
    margin-bottom: 0;
    
  }
  a {
    font-size: rem-calc(14);
    margin-top: 0;
  }
  }
  .map {
    margin-top: rem-calc(38);
    img {
      width: 100%;
    height: auto;
  }
  }
  div#gmap {
    width: 100%;
    height: 516px;
    text-align: center;
    font-size: .75em;
    line-height: 120%;
    padding: 0;
    overflow: hidden;
}
  h3.error {
    color: #eb4700;
    font-size: rem-calc(27);
    font-weight: 300;
    text-align:center;
  }
  h3.ok {
    color: #94a100;
    font-size: rem-calc(27);
    font-weight: 300;
    text-align:center;
  }

} 