.noticies {
  .categp {
    text-transform: uppercase;
    color: $primary-color;
    font-size: rem-calc(14);
    font-weight: normal;
    margin-bottom: rem-calc(5);
    margin-top: rem-calc(40);
  }

  .categ-blanc {
    text-transform: uppercase;
    color: #fff;
    font-size: rem-calc(14);
    font-weight: normal;
    margin-bottom: rem-calc(5);
    text-align: left;
    letter-spacing: 4px;
    margin-top: rem-calc(50);
  }
  .categ {
    a {
      text-decoration: none;
    }
  }
 
  .notprincipal {
    //margin-top: rem-calc(76);
    max-height: 338px;
    margin-bottom: rem-calc(30);
    img {
     max-height: rem-calc(338);
     float: left;
     margin-left: rem-calc(-10);
    }
    .fons {
      background-color: #f19600;
      height: 338px;
    }
    h2 {
      font-size: rem-calc(32);
      font-weight: 300;
      color: #4a4a4a;
      text-align: left;
      text-transform: none;
      letter-spacing: 1px;
      &:before {
        content: none;
      }
      &:after {
        content: none;
      }
    }
    p {
      text-align: left;
      color: #fff;
      font-size: rem-calc(14);
      margin-right: rem-calc(50);
    }
  }
  h3 {
    font-size: rem-calc(32);
    font-weight: 300;
    line-height: 1.2;
    color: #4a4a4a;
  }
  h4 {
    font-size: rem-calc(18);
    color: $primary-color;
    margin-top: rem-calc(22);
  }
  .cos-noticia {
    text-align: left;
  }
  .fitxa-not {
    max-width: rem-calc(510);
    margin-left: 10%;
    p {
      font-size: rem-calc(14);
      color: #4a4a4a;
    }
  }
  .destacat {
    max-width: rem-calc(200);
    margin-right: 12%;
  }
  .frase-destacada {
    color: #4a4a4a;
    font-weight: 300;
    font-size: rem-calc(24);
    line-height: 1;
    margin-bottom: rem-calc(30);
    &:before {
      content: " ";
      background-image: url('img/cometes.png') ;
      height: 31px;
      width: 29px;
      display: block;
      margin-left: rem-calc(-4);
      margin-bottom: rem-calc(5);
    }
  }
  .compartir {
    text-align: center;
    margin-top: rem-calc(40);
    width: 100%;
    height: rem-calc(233);
    background: #3a3a3a; 
    background: -webkit-linear-gradient(#3a3a3a, #5b5b5b); 
    background: -o-linear-gradient(#3a3a3a, #5b5b5b); 
    background: -moz-linear-gradient(#3a3a3a, #5b5b5b); 
    background: linear-gradient(#3a3a3a, #5b5b5b); 
    h2 {
      font-weight: 300;
      color: #fff;
      letter-spacing: 2.5px;
      font-size: rem-calc(15);
      margin-top: rem-calc(45);
    }
    .logos li {
      display: inline-block;
      margin-top: rem-calc(40);
      margin-left: rem-calc(25);
      margin-right: rem-calc(25);
      &:before {
        background: none !important;
      }
      a {
        display: inline-block;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
  .titol-not {
    font-size: rem-calc(24);
    line-height: 1;
    color: #4a4a4a;
    text-align: left;
    a {
      text-decoration: none;
      color: #4a4a4a;
      font-weight: 300;
    }
  }
  .titolf {
    margin-bottom: rem-calc(50);
  }
}
figure {
  margin: 0 0 2rem;

  img {
    margin-bottom: 1rem;
  }
}

figcaption {
  font-size: rem-calc(12);
  color: #4a4a4a;
  font-weight: 300;
  margin-top: rem-calc(-5);

  @media #{$small-up} {
    font-size: rem-calc(12);
  }

  @media #{$xlarge-up} {
    font-size: rem-calc(12);
  }
}
.ico-segria {
  padding-top: rem-calc(47);
  display:block;
}
.ico-garrigues, .ico-garrigues-b {
  padding-top: rem-calc(23);
  margin-top: rem-calc(24);
  display:block;
}

.ico-segarra {
  padding-top: rem-calc(30);
  margin-top: rem-calc(17);
  display:block;
}
.ico-pla-urgell {
  display:block;
  margin-top: rem-calc(32);
}
.ico-noguera {
  display:block;
  margin-top: rem-calc(13);
}
.categp {
  .ico-segria {
  margin: 0 auto;
}
.ico-garrigues {
 margin: 0 auto;
}
.ico-segarra {
 margin: 0 auto;
}
.ico-pla-urgell {
  margin: 0 auto;
}
.ico-noguera {
  margin: 0 auto;
}
}