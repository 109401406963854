@media only screen and (max-width: $small-breakpoint) {
    .main-nav {
        background: $primary-color;
        padding: rem-calc(27 23);
        transition: all 0.3s ease-out;

        .js & {
            display: none;
            position: absolute;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100vh;
            opacity: 0;

            &.open {
                display: block;
                opacity: 1;
            }
        }
    }

    .main-nav__menu {
      font-size: rem-calc(20);
      margin: 0 0 rem-calc(36);

      @media #{$landscape} {
          column-count: 2;
      }

      li {
        line-height: 1.2;
      }

      a {
        color: $white;
        display: block;
        padding: 0.5em 0;
        @include hover;
      }
    }
}

.open {
  // nav mobile
  //margin-bottom: $paragraph-margin-bottom;
  background: $secondary-color;
  padding: rem-calc(27 23);
  transition: all 0.3s ease-out;
  margin-top: rem-calc(-20);

  h2 {
    @include visually-hidden;
  }

  ul {
    list-style: none;
    font-size: rem-calc(12);
    margin: 0;
    
    margin-top: rem-calc(-15);

    a {
      color: $white;
      display: block;
      padding: 0.5em 0;
      background-color: $secondary-color;
      letter-spacing: 2px;
      font-weight: 700;
      text-transform: uppercase;
    }
    .submenu {
      margin-top: rem-calc(5);
      li {

      a {
        text-transform: none;
        color: #fffdc4;
        font-weight: 400;
        border-bottom: none;
      }
      }
    }
  }
    
  }

.main-nav {



  // nav medium
  @media #{$medium-up} {

  // @include clearfix;
    //display: block !important;
    //background: $secondary-color;
    //margin-bottom: rem-calc(44);
    //padding: 0;
    //position: static !important;
    //width: auto !important;
    //margin-top: 0 !important;

    .menu-wrapper {
      //@include grid-row;
    }

    ul {
      //@include grid-column;
      //position: relative;
      //font-size: rem-calc(16);
      //float: none;

      li {
        //display: inline-block;
        //position: relative;

        > a {
          //color: $black;
          //padding: 0.75em;

          &:hover,
          &.is-hovered {
            //text-decoration: none;
            //background: $white;
            //color: $black;
            &:after {
              //border-top-color: $secondary-color !important;
            }
          }
        }

        .home &.inici {
          display: none;
        }

        &.active {
          > a {
            //background: $primary-color;
            //color: $white;
          }
        }

        &.has-submenu {
          > a {
            &:after {
              @include triangle(4px, $white, top);
              display: inline-block;
              margin-left: 10px;
            }
          }
        }

        &.has-submenu:hover a + .submenu {
          left: auto;
        }
      }
    }

    .submenu {
      position: absolute;
      width: rem-calc(600);
      padding: 0;
      background: $white;
      overflow: hidden;
      z-index: 10000;
      display: block;
      margin: 0;
      text-transform: none;
      left: -999999em;
      top: 50px;
      text-align: left;
      font-size: rem-calc(10);
      -webkit-column-count: 2; 
      -moz-column-count: 2; 
      column-count: 2;


      li {
        float: none;
        display: block;
        
      }

      a {
        padding: 0.75em 1.5em;
        height: auto;
        color: #4a4a4a;
        border-bottom: solid 1px #dadada !important;
        text-transform: uppercase;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  

}

.main-nav {
  font-family:  $font-family-sans-serif;
 
  .js & {
    position: absolute;
    //top: 0;
    right: 0;
  }

  h2 {
    @include visually-hidden;

  }

  ul {
    margin-bottom: 0;
  }

  a {
    color: $white;
    background: $body-font-color;
    display: block;
    padding: 0.5rem $column-gutter/2;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    text-decoration: none;
  }

  .nav-collapse {
    display: none;
  } 
}

.nav-toggle {
  z-index: 1;
  margin-right: $column-gutter/2;
  margin-top: rem-calc(17);
  position: absolute;
  right: 0;
  top: 0;
}
@media #{$medium-up} {
  nav.main-nav {
    text-align: center;
    background: $body-font-color;
    background:$secondary-color;
    padding: 1.5rem 0 1.2rem;
    //top: 137px !important;
    position: absolute !important;
    width: 100%;
    clip: auto !important;
    z-index: 10;
    .home & {
      background: rgba(0, 0, 0, 0.13);
    }
    .ie9 & {
      z-index: 1000;
    }
    .ie8 & {
      z-index: 1000;
    }

    ul {
      font-size: rem-calc(12);
      text-transform: uppercase;
      font-weight: normal;
      width: 80%;
      margin: 0 auto;
    }

    li {
      display: inline-block;
      width: auto;
      padding: 0 .8rem;
      &.active a{
        border-bottom: 1px solid $white;
      }
    }

    a {
      color: $white;
      background: transparent;
      letter-spacing: 1px;
      margin: 0 0;
      padding: 0 0;
      line-height: 2.2;
      border-bottom: 1px solid rgba($white, 0);
      transition: border-bottom-color 0.2s ease-out;
      font-family:  $font-family-sans-serif;
      font-weight: 700;

      &:hover {
        border-bottom: 1px solid $white;
      }
    }

    &.sticky {
      position: fixed !important;
      left: 0;
      top: 0 !important;
      z-index: 2;
      padding-top: 1.5rem;
      // background: rgba(0, 0, 0, 0.8);
    }
  }
}

@media #{$large-up} {
  nav.main-nav {
    padding: 1.5rem 0 1.2rem;
    ul {
      font-size: rem-calc(12);
      width: 100%;
    }

    a {
      letter-spacing: 2px;
      margin: 0 0.25rem;
      font-weight: 700;
      padding: 0 0.25rem;
    }
  }
}
@media #{$xlarge-up} {
  nav.main-nav {
    padding: 1.5rem 0 1.2rem;
  }
}
@media #{$xxlarge-up} {
  nav.main-nav {
    //top: 173px !important;
  }
}
