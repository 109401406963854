.espai {
  .img-projecte {
    margin-top: 1.8rem;
    margin-bottom: rem-calc(50);
  }
  div#gmap {
    width: 100%;
    height: 230px;
    text-align: center;
    font-size: .75em;
    line-height: 120%;
    padding: 0;
    overflow: hidden;
}
  .destacat-espai {
    color: #4a4a4a;
    font-weight: 300;
    font-size: rem-calc(20);
    margin: 0 auto;
    width: 65%;
    margin-bottom: rem-calc(20);
  }
  .text-fitxa {
    text-align: left;
    font-size: rem-calc(16);
    color: #4a4a4a;
    width: 65%;
    margin: 0 auto;
    margin-top: rem-calc(22);
    margin-bottom: rem-calc(40);
    -webkit-column-count: 2; 
    -moz-column-count: 2; 
    column-count: 2;
  }
  @media only screen and (max-width: $small-breakpoint) {
  .text-fitxa {
    width: 80%;
     margin-top: rem-calc(10);
    margin-bottom: rem-calc(10);
    -webkit-column-count: 1; 
    -moz-column-count: 1; 
    column-count: 1;
  }
  .img-projecte {
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(20);
  }
  .destacat-espai {
    width: 80%;
    margin-bottom: 0;
  }
  }
  .fotos {
    img {
      margin-bottom: rem-calc(15);
    }
  }
  .caixeta1 {
    background-color: #d88815;
    min-height: rem-calc(230);
    text-align: left;
    margin-top: rem-calc(20);
    .visita {
      font-size: rem-calc(35);
      color: #fff;
      letter-spacing: 5.8px;
      font-weight: 700;
      line-height: 1.26;
      margin-top: rem-calc(26);
      margin-left: rem-calc(35);
    }
    
    .button {
    background-color: transparent;
    border: solid 1px #fff;
    border-radius: 5px;
    font-size: rem-calc(11);
    color: #fff;
    text-transform: uppercase;
    height: rem-calc(31);
    text-align: center;
    padding-top: rem-calc(8);
    letter-spacing: 3.1px;
    margin-left: rem-calc(35);
    &:hover {
      opacity: .6;
    }
  }
  }
  .caixeta2 {
      margin-top: rem-calc(20);
    }
  .caixeta3 {
    text-align: left;
     margin-top: rem-calc(20);
    h4 {
      font-size: rem-calc(15);
      color: #0d0d0d;
      letter-spacing: 2.5px;
      font-weight: 700;
      padding-bottom: rem-calc(14);
      border-bottom: solid 1px #c8c8c8;
    }
    p {
      font-size: rem-calc(14);
      color: #0d0d0d;
    }
    a {
      color: #d888154 !important;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .fitxa-dades {
      margin-left: 0 !important;
      margin-top: rem-calc(-15);
      font-size: rem-calc(14);
       li {
        margin-bottom: rem-calc(10) !important;
        &:before {
          background: none !important;
        }
        a {
          padding-left: rem-calc(10);
          span {
            display: block;
            margin-top: rem-calc(-27);
            margin-left: rem-calc(5);
          }
        }
       }
      }
    }
    .caixetes {
      margin-top: rem-calc(10);

    }

    .accordion {
      margin-top: rem-calc(40);
      text-align: left;
      margin-left: 0 !important;
      .desplegar {
        float: right;
        margin-top: rem-calc(-10);
      }
      .plegar {
        float: right;
        margin-top: rem-calc(-10);
      }
       li {
        margin-bottom: rem-calc(32) !important;
        .apartats {
          border-bottom: solid 1px #c8c8c8;
          padding-bottom: rem-calc(40);
        }
        .destacat {
          text-align: center;
          font-size: rem-calc(20);
          font-weight: 300;
          color: #4a4a4a;
          width: 70%;
          margin: 0 auto;
          margin-top: rem-calc(30);

        }
        ul {
          list-style-type: disc !important;
          padding-left: rem-calc(15);
          li {
            margin-bottom: rem-calc(5) !important;
            padding-left: 0 !important;
          }
        }
        .text-accordio {
          text-align: left;
          font-size: rem-calc(16);
          color: #4a4a4a; 
          margin-top: rem-calc(22);
          margin-bottom: rem-calc(20);
          p {
            margin-left: rem-calc(10);
            margin-right: rem-calc(10);
          }
          h5 {
            margin-left: rem-calc(10);
          }
          a {
            font-size: rem-calc(14);
            letter-spacing: 1px;
            padding-left: rem-calc(1);
          }
        }
        h3 {
          margin-top: rem-calc(50);
          margin-bottom: rem-calc(20);
          font-size: rem-calc(15);
          color: #0d0d0d;
          letter-spacing: 2.5px;
          font-weight: 700;
          margin-left: rem-calc(12);
        }
          a {
            text-decoration: none;
            font-size: rem-calc(15);
            letter-spacing: 2.5px;
            font-weight: 700;
            padding-top: 25px;
            padding-bottom: 25px;
            padding-left: rem-calc(40);
          }

        &:before {
          background: none !important;
        }
      }
      img {
        margin: rem-calc(10);
      }
      .ac1 {
        background-color: #94a100;
      }
      .ac2 {
        background-color: #767805;
      }
      .ac3 {
        background-color: #007800;
      }
    }

 
}